// export const BASE_URL = "https://kno-gateway-ar413sd3.uc.gateway.dev/";
export const BASE_URL = "https://kno-ak01so42.uc.gateway.dev/";

export const API_END_POINTS = {
  /////////////////////////////<===MUTATIONS===>//////////////////////////////
  registerUser: BASE_URL + "user/mobile/create-user-with-intake",
  registerSocialUser: BASE_URL + "user/mobile/create-user-social",
  loginUser: BASE_URL + "auth/mobile/otp/login",
  loginSocialUser: BASE_URL + "user/mobile/social-login",
  otpLogin: BASE_URL + "auth/mobile/login",
  logout: BASE_URL + "protected/auth/mobile/logout",
  deleteAcount: BASE_URL + "protected/user/mobile/delete",
  forgotPassword: BASE_URL + "auth/mobile/forgot-password-web",
  resetPassword: BASE_URL + "auth/mobile/reset-password",
  updateProfile: BASE_URL + "protected/user/mobile/edit",
  createCheckoutSession: BASE_URL + "protected/payment/mobile/create-checkout-session",
  createOrderWithKit: BASE_URL + "protected/test/mobile/create-order-with-kit",
  checkUserEmailPhone: BASE_URL + "user/mobile/check-email-phone",
  intakeDetails: BASE_URL + "protected/test/mobile/intake-details",
  /////////////////////////////<===QUERIES===>////////////////////////////////
  getProfile: BASE_URL + "protected/user/mobile/profile",
  getStates: BASE_URL + "test/mobile/states",
  getCities: BASE_URL + "test/mobile/city",
  getPlan: BASE_URL + "protected/payment/mobile/products/list",
  getFaqs: BASE_URL + "user/mobile/faq",
  getOrderStatus: BASE_URL + "protected/test/mobile/order-status",
};