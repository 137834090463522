import './App.css';
import './pages/home/Home.css';
import "./components/formStyleSheet/FormStyling.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import Router from "./routes/routes";
import Navbar from "./components/navbar/Navbar";
import { useLocation } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { LogoutRemoveFunction } from './components/logoutFunction/LogoutRemoveFunction';
import PATHS from './routes/Paths';

function App() {

  const location = useLocation();
  const allowedPaths = ['/home', '/shippingPolicy', '/returnPolicy', '/privacyPolicy', '/terms', '/about', '/allBlogs', PATHS.allBlogs, PATHS.blog1, PATHS.blog2, PATHS.blog3, PATHS.blog4, PATHS.blog5, PATHS.blog6, PATHS.blog7, PATHS.blog8, PATHS.faqs];
  const isAllowedPath = allowedPaths?.includes(location?.pathname);
  const auth = useSelector((data) => data?.auth);

  useEffect(() => {
    if (!auth?.isLoggedIn && location?.pathname == '/home') {
      LogoutRemoveFunction();
    }
  }, [location || !auth?.isLoggedIn]);

  return (
    <div className="App">
      <ScrollToTop />
      {!isAllowedPath ?
        <Navbar />
        : null}
      <Router />
    </div>
  );
}

export default App;