import React, { Suspense, lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import PATHS from "./Paths";
import { useSelector } from "react-redux";
import Loader from "../components/loader/Loader";

const Loadable = (Component) => (props) => {
    return (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    );
};
export default function Router() {

    const auth = useSelector((data) => data.auth);

    return useRoutes(
        // auth?.isLoggedIn ?
        //     [
        //         { path: PATHS.profile, element: <Profile /> },
        //         { path: PATHS.getAddress, element: <GetAddress /> },
        //     ] : [
        [
            { path: "/", element: <Navigate to={PATHS.home} /> },
            { path: PATHS.home, element: <Home /> },
            { path: PATHS.signin, element: <SignIn /> },
            { path: PATHS.otp, element: <Otp /> },
            { path: PATHS.forgotPassword, element: <ForgotPassword /> },
            { path: PATHS.checkEmail, element: <CheckEmail /> },
            { path: PATHS.resetPassword, element: <ResetPassword /> },
            { path: PATHS.resetPasswordSuccess, element: <ResetPasswordSuccess /> },
            { path: PATHS.whatToExpect, element: <WhatToExpect /> },
            { path: PATHS.whatWeTest, element: <WhatWeTest /> },
            { path: PATHS.receivingResult, element: <ReceivingResult /> },
            { path: PATHS.contactInfo, element: <ContactInfo /> },
            { path: PATHS.almostDone, element: <AlmostDone /> },
            { path: PATHS.currentlyExperiencing, element: <CurrentlyExperiencing /> },
            { path: PATHS.getAddress, element: <GetAddress /> },
            { path: PATHS.completeRegistration, element: <CompleteRegistration /> },
            { path: PATHS.receivingResultForPartner, element: <ReceivingResultForPartner /> },
            { path: PATHS.currentSymptoms, element: <CurrentSymptoms /> },
            { path: PATHS.partnersSymptoms, element: <PartnersSymptoms /> },
            { path: PATHS.profile, element: <Profile /> },
            { path: PATHS.shippingPolicy, element: <ShippingPolicy /> },
            { path: PATHS.returnPolicy, element: <ReturnPolicy /> },
            { path: PATHS.privacyPolicy, element: <PrivacyPolicy /> },
            { path: PATHS.terms, element: <Terms /> },
            { path: PATHS.about, element: <About /> },
            { path: PATHS.faqs, element: <Faqs /> },
            { path: PATHS.paymentSuccess, element: <PaymentSuccess /> },
            { path: PATHS.haveKit, element: <HaveKit /> },
            { path: PATHS.success, element: <Success /> },
            { path: PATHS.alreadyOrdered, element: <AlreadyOrdered /> },
            { path: PATHS.allBlogs, element: <AllBlogs /> },
            { path: PATHS.blog1, element: <Blog1 /> },
            { path: PATHS.blog2, element: <Blog2 /> },
            { path: PATHS.blog3, element: <Blog3 /> },
            { path: PATHS.blog4, element: <Blog4 /> },
            { path: PATHS.blog5, element: <Blog5 /> },
            { path: PATHS.blog6, element: <Blog6 /> },
            { path: PATHS.blog7, element: <Blog7 /> },
            { path: PATHS.blog8, element: <Blog8 /> },
        ]
        // ]
    );
}

const SignIn = Loadable(lazy(() => import("../pages/auth/Signin")));
const Home = Loadable(lazy(() => import("../pages/home/Home")));
const Otp = Loadable(lazy(() => import("../pages/auth/Otp")));
const ForgotPassword = Loadable(lazy(() => import("../pages/auth/ForgotPassword")));
const CheckEmail = Loadable(lazy(() => import("../pages/auth/CheckEmail")));
const ResetPassword = Loadable(lazy(() => import("../pages/auth/ResetPassword")));
const ResetPasswordSuccess = Loadable(lazy(() => import("../pages/auth/ResetPasswordSuccess")));
const WhatToExpect = Loadable(lazy(() => import("../pages/whatToExpect/WhatToExpect")));
const WhatWeTest = Loadable(lazy(() => import("../pages/whatWeTest/WhatWeTest")));
const ReceivingResult = Loadable(lazy(() => import("../pages/receivingResult/ReceivingResult")));
const ContactInfo = Loadable(lazy(() => import("../pages/contactInfo/ContactInfo")));
const AlmostDone = Loadable(lazy(() => import("../pages/almostDone/AlmostDone")));
const CurrentlyExperiencing = Loadable(lazy(() => import("../pages/currentlyExperiencing/CurrentlyExperiencing")));
const GetAddress = Loadable(lazy(() => import("../pages/getAddress/GetAddress")));
const CompleteRegistration = Loadable(lazy(() => import("../pages/completeRegistration/CompleteRegistration")));
const Profile = Loadable(lazy(() => import("../pages/profile/Profile")));
const ReceivingResultForPartner = Loadable(lazy(() => import("../pages/receivingResult/ReceivingResultForPartner")));
const CurrentSymptoms = Loadable(lazy(() => import("../pages/almostDone/CurrentSymptoms")));
const PartnersSymptoms = Loadable(lazy(() => import("../pages/almostDone/PartnersSymptoms")));
const ShippingPolicy = Loadable(lazy(() => import("../pages/cmsPages/ShippingPolicy")));
const ReturnPolicy = Loadable(lazy(() => import("../pages/cmsPages/ReturnPolicy")));
const PrivacyPolicy = Loadable(lazy(() => import("../pages/cmsPages/PrivacyPolicy")));
const Terms = Loadable(lazy(() => import("../pages/cmsPages/Terms")));
const About = Loadable(lazy(() => import("../pages/cmsPages/AboutUs")));
const Faqs = Loadable(lazy(() => import("../pages/cmsPages/Faqs")));
const PaymentSuccess = Loadable(lazy(() => import("../pages/paymentSuccess/PaymentSuccess")));
const HaveKit = Loadable(lazy(() => import("../pages/paymentSuccess/HaveKit")));
const Success = Loadable(lazy(() => import("../pages/paymentSuccess/Success")));
const AlreadyOrdered = Loadable(lazy(() => import("../pages/paymentSuccess/AlreadyOrdered")));
const AllBlogs = Loadable(lazy(() => import("../pages/allBlogs/AllBlogs")));
const Blog1 = Loadable(lazy(() => import("../pages/allBlogs/Blog1")));
const Blog2 = Loadable(lazy(() => import("../pages/allBlogs/Blog2")));
const Blog3 = Loadable(lazy(() => import("../pages/allBlogs/Blog3")));
const Blog4 = Loadable(lazy(() => import("../pages/allBlogs/Blog4")));
const Blog5 = Loadable(lazy(() => import("../pages/allBlogs/Blog5")));
const Blog6 = Loadable(lazy(() => import("../pages/allBlogs/Blog6")));
const Blog7 = Loadable(lazy(() => import("../pages/allBlogs/Blog7")));
const Blog8 = Loadable(lazy(() => import("../pages/allBlogs/Blog8")));