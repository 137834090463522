import React from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import '../formStyleSheet/FormStyling.css';

const AlreadyOrederdModal = (props) => {

    const handleDownload = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        let link;

        if (userAgent.includes('macintosh') || userAgent.includes('mac os x')) {
            link = 'https://apps.apple.com/us/app/kno-co/id6451420414';
        } else if (userAgent.includes('windows')) {
            link = 'https://play.google.com/store/apps/details?id=com.kno.app';
        } else {
            link = 'https://usermanagement-odk3snhvrq-uc.a.run.app/user/mobile/kno-co-app';
        }

        window.open(link, '_blank');
    };

    return (
        <ModalComponent
            size="md"
            show={true}
            handleClose={props.handleCloseAlreadyOrederdModal}
        >
            <Modal.Body className="px-3">
                {props?.completeTestStatus ?
                    <h1 className="mainHeadingModal text-center">Complete Test</h1>
                    :
                    <h1 className="mainHeadingModal text-center">Test in Progress</h1>
                }

                <div className="modalDivMargin">
                    {props?.completeTestStatus ?
                        <p className="text-center fs-3">To proceed further you will be required to download KNO app.</p>
                        :
                        <p className="text-center fs-3">It looks like you already have a test in progress with knō. Please download the knō app to keep an eye on your notifications screen and track your test status.</p>
                    }

                    <div className="text-center mt-5 mb-5" onClick={handleDownload}>
                        <Button
                            label="Download App"
                            className="gradientBtn w-100"
                        />
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent>
    );
};

export default AlreadyOrederdModal;