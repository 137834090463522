export const LogoutRemoveFunction = () => {
    localStorage.removeItem('countdownEndTime');
    localStorage.removeItem('timerCompleted');
    localStorage.removeItem('dataForReset');
    localStorage.removeItem('selectedSTIs');
    localStorage.removeItem('lastName');
    localStorage.removeItem('mixsymptomsModal');
    localStorage.removeItem('checked');
    localStorage.removeItem('haveKit');
    localStorage.removeItem('selections');
    localStorage.removeItem('orderingFor');
    localStorage.removeItem('checked2');
    localStorage.removeItem('email');
    localStorage.removeItem('firstName');
    localStorage.removeItem('phone');
    localStorage.removeItem('orderingForYou');
    localStorage.removeItem('haveKitPartner');
    localStorage.removeItem('IntakeFormData');
    localStorage.removeItem('dataForOtp');
    localStorage.removeItem('navigateRememberSession');
};