import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { download, dropArrow, logo } from '../../assets/imagesPath';
import Button from '../button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../redux/AuthSliceKno';
import { toast } from 'react-toastify';
import PATHS from '../../routes/Paths';
import { useGetOrderStatusQuery, useGetProfileQuery, useLogoutMutation } from '../../services/Api';
import { Link, useNavigate } from 'react-router-dom';
import AlreadyOrederdModal from '../allModals/AlreadyOrederdModal';
import { LogoutRemoveFunction } from '../logoutFunction/LogoutRemoveFunction';

const Navbar = () => {
  const auth = useSelector((data) => data?.auth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [alreadyOrderedModal, setAlreadyOrderedModal] = useState(false);
  const [completeTestText, setCompleteTestText] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const alreadyOrderedModalHandler = (status) => {
    setAlreadyOrderedModal((prev) => !prev);
    setCompleteTestText(status === 1);
  };

  const { data: getProfile, refetch: getProfileRefetch } = useGetProfileQuery();
  const profileData = getProfile?.data[0] || [];
  const { data: getOrderStatus, refetch: getOrderStatusRefetch } = useGetOrderStatusQuery();
  const [logout] = useLogoutMutation();

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const logoutHandler = () => {
    logout()
      .unwrap()
      .then((payload) => {
        if (payload?.success) {
          dispatch(userLogout(null));
          LogoutRemoveFunction();
          navigate(PATHS.home);
          toast.success(payload?.message);
        }
      })
      .catch((error) => {
        dispatch(userLogout(null));
        LogoutRemoveFunction();
        navigate(PATHS.home);
      });
  };

  useEffect(() => {
    getProfileRefetch();
    getOrderStatusRefetch();
  }, []);

  const handleDownload = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    let link;

    if (userAgent.includes('macintosh') || userAgent.includes('mac os x')) {
      link = 'https://apps.apple.com/us/app/kno-co/id6451420414';
    } else if (userAgent.includes('windows')) {
      link = 'https://play.google.com/store/apps/details?id=com.kno.app';
    } else {
      link = 'https://usermanagement-odk3snhvrq-uc.a.run.app/user/mobile/kno-co-app';
    }

    window.open(link, '_blank');
  };

  return (
    <>
      <nav className={`navbar navbar-expand-lg navbarKno p-4 p-lg-3 ps-lg-5 pe-lg-5 justify-content-between position-sticky top-0 ${isNavOpen ? 'nav-open' : ''}`}>
        <button className="navbar-toggler" type="button" onClick={() => setIsNavOpen(!isNavOpen)}>
          {isNavOpen ? <span></span> : <span className="navbar-toggler-icon"></span>}
        </button>

        <Link to={PATHS.home}>
          <img
            src={logo}
            alt=''
            className='img-fluid'
            style={{ height: "5rem" }}
          />
        </Link>

        <div className='hideOnMobile justify-content-end'>
          {auth?.isLoggedIn ?
            <div className='d-lg-flex d-grid justify-content-center align-items-center gap-4 gap-lg-5 mt-5 mt-lg-0'>
              <ul className='navbar-nav me-auto mb-2 mb-lg-0 gap-4 gap-lg-5 w-100 text-center flex-columnx'>
                <li className='nav-item'>
                  <Button
                    label="Download App"
                    icon={download}
                    className="gradientBtn"
                    onClick={handleDownload}
                  />
                </li>

                <li className='nav-item'>
                  <div className='dropdown profileDropdown mx-auto'>
                    <a
                      className='d-flex align-items-center flex-lg-row gap-1  text-capitalize gradientBtn'
                      href='#'
                      role='button'
                      id='dropdownMenuLink'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      {profileData?.firstName} {profileData?.lastName}
                      <img src={dropArrow} className='ms-3' alt="" />
                    </a>

                    <ul
                      className='dropdown-menu'
                      aria-labelledby='dropdownMenuLink'
                      onClick={handleDropdownToggle}
                    >
                      <Link to={PATHS.profile}>
                        <li className='cursor'>
                          <p className='dropdown-item'>Profile</p>
                        </li>
                      </Link>
                      {auth?.isLoggedIn && getOrderStatus?.data[0] ?
                        <>
                          <hr className='m-1' />
                          <li className='cursor' onClick={() => alreadyOrderedModalHandler(1)}>
                            <p className='dropdown-item'>Complete Test</p>
                          </li>
                        </>
                        : null}
                      <hr className='m-1' />
                      <li className='cursor' onClick={logoutHandler}>
                        <p className='dropdown-item'>Logout</p>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            : null}
        </div>

        <div className={`navbar-collapse ${isNavOpen ? 'show' : ''}`}>
          <button className="navbar-toggler btnSidePopup" type="button" onClick={() => setIsNavOpen(!isNavOpen)}>
            {isNavOpen ? <span className="close-btn">&times;</span> : <span className="navbar-toggler-icon"></span>}
          </button>

          <Link to={PATHS.home}>
            <img
              src={logo}
              alt=''
              className='img-fluid'
              style={{ height: "5rem" }}
            />
          </Link>

          {auth?.isLoggedIn ?
            <div className='d-lg-flex d-grid justify-content-center align-items-center gap-4 gap-lg-5 mt-5 mt-lg-0'>
              <ul className='navbar-nav me-auto mb-2 mb-lg-0 gap-4 gap-lg-5 w-100 text-center flex-column'>
                <li className='nav-item'>
                  <Button
                    label="Download App"
                    icon={download}
                    className="gradientBtn"
                    onClick={handleDownload}
                  />
                </li>

                <li className='nav-item'>
                  <div className='dropdown profileDropdown mx-auto'>
                    <a
                      className='d-flex align-items-center flex-lg-row gap-1  text-capitalize gradientBtn'
                      href='#'
                      role='button'
                      id='dropdownMenuLink'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      {profileData?.firstName} {profileData?.lastName}
                      <img src={dropArrow} className='ms-3' alt="" />
                    </a>

                    <ul
                      className='dropdown-menu'
                      aria-labelledby='dropdownMenuLink'
                      onClick={handleDropdownToggle}
                    >
                      <Link to={PATHS.profile}>
                        <li className='cursor'>
                          <p className='dropdown-item'>Profile</p>
                        </li>
                      </Link>
                      {auth?.isLoggedIn && getOrderStatus?.data[0] ?
                        <>
                          <hr className='m-1' />
                          <li className='cursor' onClick={() => alreadyOrderedModalHandler(1)}>
                            <p className='dropdown-item'>Complete Test</p>
                          </li>
                        </>
                        : null}
                      <hr className='m-1' />
                      <li className='cursor' onClick={logoutHandler}>
                        <p className='dropdown-item'>Logout</p>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            : null}
        </div>
      </nav>

      {alreadyOrderedModal &&
        <AlreadyOrederdModal
          handleCloseAlreadyOrederdModal={alreadyOrderedModalHandler}
          completeTestStatus={completeTestText}
        />
      }
    </>
  );
};

export default Navbar;