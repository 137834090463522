const PATHS = {
    signin: '/signin',
    otp: '/otp',
    home: '/home',
    forgotPassword: '/forgotPassword',
    checkEmail: '/checkEmail',
    resetPassword: '/resetPassword',
    resetPasswordSuccess: '/resetPasswordSuccess',
    whatToExpect: '/whatToExpect',
    whatWeTest: '/whatWeTest',
    receivingResult: '/receivingResult',
    receivingResultForPartner: '/receivingResultForPartner',
    contactInfo: '/contactInfo',
    almostDone: '/almostDone',
    currentlyExperiencing: '/currentlyExperiencing',
    getAddress: '/getAddress',
    completeRegistration: '/completeRegistration',
    profile: '/profile',
    currentSymptoms: '/currentSymptoms',
    partnersSymptoms: '/partnersSymptoms',
    shippingPolicy: '/shippingPolicy',
    returnPolicy: '/returnPolicy',
    privacyPolicy: '/privacyPolicy',
    terms: '/terms',
    about: '/about',
    faqs: '/faqs',
    paymentSuccess: '/paymentSuccess',
    haveKit: '/haveKit',
    success: '/success',
    alreadyOrdered: '/alreadyOrdered',
    allBlogs: '/news',
    blog1: '/blogs/news/the-importance-of-regular-sti-testing-how-often-should-you-get-tested',
    blog2: '/blogs/news/the-role-of-regular-sti-testing-in-preventing-the-spread-of-infections',
    blog3: '/blogs/news/why-people-are-prioritizing-sharing-std-testing-status-on-dating-apps-with-kno',
    blog4: '/blogs/news/sti-testing-at-planned-parenthood-vs-kno-cost-confidentiality-service',
    blog5: '/blogs/news/how-does-urgent-care-std-testing-compare-to-kno-everything-you-need-to-know-in-2023',
    blog6: '/blogs/news/alexs-syphilis-glow-up-a-personal-journey-of-discovery-and-healing',
    blog7: '/blogs/news/joffreys-mpox-debacle',
    blog8: '/blogs/news/cheryls-chlamydia-encounter',
}

export default PATHS;